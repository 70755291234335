import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa';
import { Box } from '../../Box';
import { ALIGNMENT, AwardsBadges } from '../../Badges';
import { getEarnedRewards } from '../../../selectors/rewards/getEarnedRewards';
import { useChallengeData } from '../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { useUser } from '../../../contexts/User/UserContext';
import { classes } from '../ChallengeCompletedModal.st.css';
import {
  getBadgesTrigger,
  getCertificateTrigger,
} from '../../../selectors/rewards/rewardsSelectors';

export const RewardsSection: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { challengeData } = useChallengeData();
  const { participant, user } = useUser();
  const { rewards } = getEarnedRewards(
    challengeData?.challenge?.settings?.rewards,
    participant,
  );
  const badgeTrigger = getBadgesTrigger(rewards);
  const certificateTrigger = getCertificateTrigger(rewards);

  if (!rewards.length) {
    return null;
  }

  return (
    <section>
      {certificateTrigger && (
        <Text className={classes.text}>
          {t(
            'live.challenges-page.modal.challenge-completed.reward.certificate',
            {
              participantEmail: user.email,
            },
          )}
        </Text>
      )}

      {badgeTrigger && (
        <>
          <Box justifyContent="center" marginTop={2}>
            <Text className={classes.text}>
              {t('live.challenges-page.modal.challenge-completed.reward')}
            </Text>
          </Box>

          <Box marginTop={2} justifyContent="center">
            <AwardsBadges
              removeCustomBottomMargin={true}
              alignment={ALIGNMENT.center}
            />
          </Box>
        </>
      )}
    </section>
  );
};
